
























































import 'leaflet/dist/leaflet.css';
import {Component, Vue} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import L from 'leaflet';
import mapIconLocation from '@/assets/images/map/map-marker-location.png';
import mapIconCustomer from '@/assets/images/map/map-marker-customer.png';
import mapIconUser from '@/assets/images/map/map-marker-user.png';
import mapIconDefault from '@/assets/images/map/map-marker-default.png';
import Company from '@/models/Company';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import User from '@/models/User';

const CompanyStore = namespace('company');
const CustomerStore = namespace('customer');
const UserStore = namespace('user');

@Component({
  components: {
    MapFilterComponent: () => import(
        '@/components/map/MapFilter.component.vue'),
  },
})
export default class MapOverviewView extends Vue {

  /**
   * The Elements without geoPositions
   */
  public unknownElements: (Customer | Location | User)[] = [];
  /**
   * Bool to enable centralization
   */
  public shouldBeCentered: boolean = true;
  /**
   * Map config values
   */
  public mapConfig: any = {
    url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    bounds: [],
    markerObjects: [],
  };
  private defaultCoords = {lat: 53.0765507, lng: 8.804483};
  private MAP_ICON_SCALE_QUOTIENT: number = 2;
  private MAP_DEFAULT_ZOOM: number = 10;
  private map: L.Map | undefined;
  private togglePopup: boolean = false;
  private popupCanBeClosed: boolean = false;
  /**
   * Array to find and remove markers from the map
   * @private
   */
  private allMarker: Array<{ id: string, marker: L.Marker }> = [];
  /**
   * These icons are used to show the elements on the map.
   * To change the color, download the svg file of the map marker from here: https://materialdesignicons.com/.
   * Add 'fill' Property after '<path' and chose a color. rgb() is allowed too.
   * After changing, convert the file to png.
   * @private
   */
  private iconType = {
    location: mapIconLocation,
    customer: mapIconCustomer,
    user: mapIconUser,
    default: mapIconDefault,
  };

  @CompanyStore.Getter('activeCompany')
  private _company!: Company;

  public get company() {
    return this._company;
  }

  /**
   * If a marker-changed event was emitted from the filter component, place the new marker
   */
  public markerObjectsChanged(markerObjects: (Customer | User | Location)[]) {
    markerObjects = this.getElementsWithGeoPos(markerObjects);
    if (markerObjects.length === 0) {
      this.mapConfig.markerObjects = this.company.address?.geoPosition ? [this.company] : [];
    } else {
      this.mapConfig.markerObjects = markerObjects;
    }

    this.placeMarkerObjects();

    if (this.shouldBeCentered && this.mapConfig.markerObjects.length > 0) {
      this.calculateOrientation();
    }
  }

  /**
   * Calculate the new orientation
   */
  public calculateOrientation() {
    this.shouldBeCentered = true;
    this.calculateBounds();
    // Fly to bounds. Zooms automatically
    this.map!.flyToBounds(this.mapConfig.bounds);
  }

  /**
   * Initialise the map component
   */
  public mounted() {
    this.map = L.map('worldMap').setView(this.defaultCoords, this.MAP_DEFAULT_ZOOM).on('dragstart', () => {
      this.shouldBeCentered = false;
    });
    L.tileLayer(this.mapConfig.url, {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);
  }

  /**
   * Get all elements with geoPositions. The others are added to the unknown element array, to show in a separate list
   * @param arr The array to extract the elements
   */
  public getElementsWithGeoPos(arr: (Customer | User | Location)[]) {
    const retVal: any[] = [];
    this.unknownElements = [];
    arr.forEach((item) => {
      if (item.address && item.address.geoPosition) {
        retVal.push(item);
      } else {
        this.unknownElements.push(item);
      }
    });
    return retVal;
  }

  /**
   * Calculate new bounds for moving
   * @private
   */
  private calculateBounds() {
    const lats: number[] = [];
    const longs: number[] = [];
    this.mapConfig.markerObjects.forEach((object: any) => {
      lats.push(object.address.geoPosition.lat);
      longs.push(object.address.geoPosition.lng);
    });
    const corner1 = L.latLng(Math.max(...lats), Math.max(...longs));
    const corner2 = L.latLng(Math.min(...lats), Math.min(...longs));
    this.mapConfig.bounds = L.latLngBounds(corner1, corner2);
  }

  /**
   * Place the markers on the map. All currently placed markers are removed before placing the new
   * @private
   */
  private placeMarkerObjects() {
    // remove all markers before adding them. Otherwise marker are displayed more than once
    this.removeMarker();
    for (const markerObject of this.mapConfig.markerObjects) {
      const mapMarker = L.marker(markerObject.address.geoPosition).setIcon(this.getIcon(markerObject))
          .bindPopup(`<div>
            <b> ${markerObject.name ? markerObject.name : markerObject.fullName} </b><br/>
          <span> ${markerObject.address.street} ${markerObject.address.houseNo} </span><br/>
          <span> ${markerObject.address.postalCode} ${markerObject.address.city} </span><br/>
          <span> ${markerObject.address.country ? this.$t(`GENERAL.COUNTRIES.${markerObject.address.country.toUpperCase()}`) : ''} </span>
          </div>`);
      mapMarker.on('mousemove click mouseover', (ev) => {
        if (ev.type === 'mousemove') {
          this.popupCanBeClosed = true;
        }
        if (ev.type === 'click') {
          this.togglePopup = true;
        }
        mapMarker.openPopup();
      }).on('mouseout', () => {
        if (!this.togglePopup && this.popupCanBeClosed) {
          mapMarker.closePopup();
          this.popupCanBeClosed = false;
        }
        this.togglePopup = false;
      });
      // create extra tooltip in case of customer-instance
      if (markerObject instanceof Customer) {
        const tooltip = L.tooltip({
          interactive: true,
          permanent: true,
          direction: 'top',
          offset: [0, -15],
        }).setContent(`${markerObject.name}`);
        mapMarker.bindTooltip(tooltip);
      }
      mapMarker.addTo(this.map!);
      this.allMarker.push({id: markerObject.id, marker: mapMarker});
    }
  }

  /**
   * Remove all placed markers
   * @private
   */
  private removeMarker() {
    this.allMarker.forEach((mapMarker) => mapMarker.marker.remove());
    this.allMarker = [];
  }

  /**
   * Get the icon of the given object. The icon depends of the type of the object
   * @param object The object you want the icon from
   * @private
   */
  private getIcon(object: any) {
    let type: string = 'default';
    if (object instanceof Customer) {
      type = 'customer';
    } else if (object instanceof Location) {
      type = 'location';
    } else if (object instanceof User) {
      type = 'user';
    }
    const scaleQuotient = type === 'customer' ? this.MAP_ICON_SCALE_QUOTIENT - 0.2 : this.MAP_ICON_SCALE_QUOTIENT;
    // get the icon. It depends on the type of the object. Get the type via 'constructor.name'
    return L.icon({
      iconUrl: this.iconType[type],
      iconSize: [75 / scaleQuotient, 75 / scaleQuotient],
    });
  }
}
