













import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        DashboardComponent: () => import(
            /* webpackChunkName: "CompanyDashboardComponent" */
            '@/components/company/CompanyDashboard.component.vue'),
    },
})
export default class CompanyDashboardView extends Vue {

}
